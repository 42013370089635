import React from "react";
import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { TelstraStaffAuth } from "@mfcore/telstra-auth";
import { authConfigs, envs } from "./auth-config";
import img500 from "./assets/500.svg";
import { URL_PARAM_VALUES } from "./constant";
declare const window: any;

const registerApplications = () => {
  const routes = constructRoutes(microfrontendLayout);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
};

const getContextualLaunchData = () => {
  const contextualLaunchData = {
    usertype: "",
    cidn: "",
    isError: false,
  };
  try {
    const windowParams = window?.location?.search.slice(1).split("&");
    const firstWindowParam = windowParams?.[0].split("=");
    const secondWindowParam = windowParams?.[1].split("=");

    const firstWindowParamKey = firstWindowParam[0].toLowerCase();
    if (firstWindowParamKey in contextualLaunchData) {
      contextualLaunchData[firstWindowParamKey] =
        firstWindowParam[1].toLowerCase();
    }
    const secondWindowParamKey = secondWindowParam[0].toLowerCase();
    if (secondWindowParamKey in contextualLaunchData) {
      contextualLaunchData[secondWindowParamKey] =
        secondWindowParam[1].toLowerCase();
    }
    const isNumber = /^\d+$/.test(contextualLaunchData.cidn);

    if (
      contextualLaunchData.usertype !== URL_PARAM_VALUES.STAFF ||
      contextualLaunchData.cidn.length !== URL_PARAM_VALUES.CIDN_LENGTH ||
      !isNumber
    ) {
      throw new Error("Invalid Params");
    }
  } catch (error) {
    contextualLaunchData.isError = true;
  }

  return contextualLaunchData;
};

const getContextualLaunchSettings = (usertype, cidn) => {
  let settings;
  window.cidn = cidn;
  window.usertype = usertype;
  if (authConfigs?.[usertype]?.[window.env]) {
    settings = authConfigs[usertype][window.env];
    settings.redirect_uri =
      settings.redirect_uri + "?usertype=" + usertype + "&cidn=" + cidn;
    window.settings = settings;
    const telstraAuthSettings = {
      settings: settings,
      autoLogin: true,
      env: envs.env,
    };
    window.telstraAuthSettings = telstraAuthSettings;
  } else {
    console.log("Settings dont exist");
  }
};
const enableSecureAuthIntegration = () => {
  const authProvider = new TelstraStaffAuth({
    ...window.telstraAuthSettings,
  });
  window.authProvider = authProvider;
  authProvider.init().then((res) => {
    authProvider.processSignIn().then((user) => {
      registerApplications();
    });
  });
};

const renderErrorScreen = () => {
  document.querySelector("body").style.backgroundColor = "#FBF8F3";
  document.getElementById("error-screen").style.display = "flex";
  document.getElementById("error-img").style.backgroundImage = `url(${img500})`;
};

if (window.env === "LOCAL" || window.env === "SVT") {
  registerApplications();
} else {
  const contextualLaunchData = getContextualLaunchData();
  const { usertype, cidn, isError } = contextualLaunchData;
  if (!isError) {
    getContextualLaunchSettings(usertype, cidn);
    enableSecureAuthIntegration();
  } else {
    renderErrorScreen();
  }
}
